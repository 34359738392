import type { ComposableFunctionArgs } from '~/composables';
import type { FacetSearchParams } from '~/modules/catalog/category/composables/useFacet';
import { ProductAttributeFilterInput } from '~/modules/GraphQL/types';

export const rangeFilters = ['price'];

export function createProductAttributeFilterInput(params: ComposableFunctionArgs<FacetSearchParams>, categoryID: any): ProductAttributeFilterInput {
  const attributeFilter: Record<string, { from: number; to: number } | { eq: unknown } | { in: unknown }> = {};
  const inputFilters = params?.filters ?? {};
  const pattern =
    /\b(00|utm|gclid|no_cache|{_}mof_data|_switchedSiteLabel|utm_source|utm_medium|utm_campaign|utm_term|utm_content|cmp|msclkid|_mkpid|_mkpc|fbclid|epik|frz-flush|frz-reason|ranEAID|ranMID|ranSiteID|wbraid|ctt_adid|ctt_adnw|ctt_ch|ctt_cli|ctt_nwtype|auctid|_mof_data|_mof_selected|key|cat|___store|_gl|___from_store|frz-smartcache-fragment|psafe_param|_p_c|pp)\b/;
  for (const key in inputFilters) {
    if (pattern.test(key)) {
      delete inputFilters[key];
    }
  }
  let categoryFilter: any = {
    category_uid: { in: [params.category_uid, ...(inputFilters.category_uid ?? [])] }
  };

  if (!Object.keys(inputFilters).length && !categoryID.length) {
    categoryFilter = {
      category_id: { eq: categoryID }
    };
  }

  if (params.last_added_filter_attribute?.attribute_code && params.last_added_filter_attribute.options?.length) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryFilter.last_added_filter_attribute = params.last_added_filter_attribute;
  }

  Object.keys(inputFilters).forEach((key: string) => {
    if (rangeFilters.includes(key)) {
      const range = { from: 0, to: 0 };
      const flatValue = inputFilters[key]
        .flatMap((inputFilter) => inputFilter.split('_'))
        .map((str: string) => Number.parseFloat(str))
        .sort((a, b) => a - b);

      [range.from] = flatValue;
      range.to = flatValue[flatValue.length - 1];

      attributeFilter[key] = range;
    } else if (typeof inputFilters[key] === 'string') {
      attributeFilter[key] = { eq: inputFilters[key] };
    } else if (inputFilters[key].length === 1) {
      attributeFilter[key] = { eq: inputFilters[key][0] };
    } else {
      attributeFilter[key] = { in: inputFilters[key] };
    }
  });

  return { ...attributeFilter, ...categoryFilter };
}
