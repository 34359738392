



































































































import {
  computed,
  defineComponent,
  inject,
  watch,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import { SfScrollable, SfAccordion } from '@storefront-ui/vue';
import type { Aggregation, AggregationOption } from '~/modules/GraphQL/types';
import { useFilterHelper } from '~/modules/catalog/category/composables/useFilterHelper';
import { useUiHelpers } from '~/composables';
import VaimoCheckbox from 'molecules/VaimoCheckbox.vue';
import { getCurrencySymbol } from '~/diptyqueTheme/helpers/priceFormatter';
import { useFiltersStore } from '~/diptyqueTheme/stores/filters';

export default defineComponent({
  components: {
    SfScrollable,
    VaimoCheckbox,
    SfAccordion
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true
    },
    catId: {
      type: Number,
      required: true
    },
    aggregations: {
      required: false,
      default: () => [],
      type: Array
    },
    isAllValuesSelected: Boolean,
    loading: Boolean
  },
  emits: ['selectFilter'],
  setup(props, context) {
    const { changeFilters } = useUiHelpers();
    const filtersStore = useFiltersStore();
    const { isFilterSelected, selectedFilters, doApplyFilters } =
      inject('UseFiltersProvider');
    const { getLabel, resetCategory, isResetButtonVisible } =
      useFilterHelper(context);
    const isAllSelected = ref<boolean>(false);

    const isPriceFilter = (filter) => {
      return filter?.attribute_code === 'price';
    };

    watch(
      () => props.isAllValuesSelected,
      (newValue) => {
        isAllSelected.value = newValue;
      }
    );

    const disableIncompatible = computed(() => (option) => {
      const currentFilterOptions = props.aggregations.find(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (filter) => filter.attribute_code === props.filter.attribute_code
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      )?.options;
      const notIncludesFilters = props.filter.options.filter((obj1) =>
        currentFilterOptions?.every((obj2) => obj1.value !== obj2.value)
      );
      return (
        notIncludesFilters.includes(option) ||
        !props.aggregations.some(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (filter) => filter.attribute_code === props.filter.attribute_code
        )
      );
    });

    const selected = computed(
      () => (id: string, optVal: string) => isFilterSelected(id, optVal)
    );

    const reset = (code: string): void => {
      resetCategory(code, filtersStore.selectedFilters[props.catId]);
    };

    const changeAndApplyFilters = (): void => {
      changeFilters(filtersStore.selectedFilters[props.catId], false);
      doApplyFilters();
    };

    const selectAll = (data: Aggregation, code: string): void => {
      if (!isAllSelected.value && !getSelectedFiltersLength.value) {
        data.options.forEach((option: AggregationOption) => {
          if (
            !filtersStore.selectedFilters[props.catId][code]?.includes(
              option.value
            )
          ) {
            context.emit('selectFilter', option);
          }
        });
        isAllSelected.value = true;
      } else {
        filtersStore.currentFilterCache = {};
        filtersStore.lastSelectedFilter = {};
        reset(code);
        isAllSelected.value = false;
      }
      isAllSelected.value = false;
      changeAndApplyFilters();
    };

    const selectFilterValue = (option: Aggregation): void => {
      context.emit('selectFilter', option);
      changeAndApplyFilters();
    };

    const getSelectedFiltersLength = computed(
      () => filtersStore.selectedFilters[props.catId][props.filter?.attribute_code]?.length
    );

    return {
      selected,
      reset,
      selectAll,
      isPriceFilter,
      getLabel,
      getSelectedFiltersLength,
      isAllSelected,
      selectedFilters,
      disableIncompatible,
      isResetButtonVisible,
      selectFilterValue,
      getCurrencySymbol
    };
  }
});
