



















import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType
} from '@nuxtjs/composition-api';

import { useBundleStore } from '~/diptyqueTheme/stores/bundleProduct';
import { getName as getProductName } from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';
export default defineComponent({
  name: 'BundleProduct',
  components: {
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue'),
    BundleProducts: () =>
      import(
        '~/modules/catalog/product/components/product-types/bundle/parts/BundleProducts.vue'
      ),
    BundleProductStickyBar: () =>
      import(
        '~/modules/catalog/product/components/product-types/bundle/parts/BundleProductStickyBar.vue'
      )
  },
  scrollToTop: true,
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null
    },
    isFetching: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { setBundleSKU, resetState } = useBundleStore();

    const productShortDescription = computed(
      () => props.product?.short_description?.html || ''
    );

    const productDescription = computed(
      () => props.product?.short_description?.html || ''
    );

    onMounted(() => {
      setBundleSKU(props.product.sku);
      /*
       * Added bottom padding to avoid any overlap with the language selection panel.
       * */
      document.getElementById('__layout').style.paddingBottom = '62px';
    });

    onBeforeUnmount(() => {
      document.getElementById('__layout').style.paddingBottom = '0px';
    });

    onBeforeUnmount(() => {
      resetState();
    });

    return {
      productDescription,
      getProductName,
      productShortDescription
    };
  }
});
