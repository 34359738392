





















































import { computed, defineComponent } from '@vue/composition-api';

import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { useBundleStore } from '~/diptyqueTheme/stores/bundleProduct';
import { getName as getProductName } from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'BundleProductCard',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoQuantitySelector: () => import('organisms/VaimoQuantitySelector.vue'),
    VaimoBadge: () => import('atoms/VaimoBadge.vue')
  },
  props: {
    bundle: {
      required: true,
      type: Object
    }
  },
  setup() {
    const bundleStore = useBundleStore();
    const getProductDescription = (product: Product): string => {
      return product?.plp_description || '';
    };
    const getQuantity = (product): number => {
      return bundleStore.getProductQuantity(product);
    };

    const getMaxNumberOfSets = computed(() => +bundleStore.numberOfSets);

    const isMaxItemsAdded = computed(() => bundleStore.isMaxProductsAdded);

    const getProductHoverImage = (product) => {
      if (!product || !product['hover_on_card']) {
        return null;
      }
      return product['hover_on_card'];
    };

    const addProduct = (item, quantity): void => {
      if (quantity <= bundleStore.getBundleProductsQuantitySum) {
        bundleStore.addBundleProduct(item, quantity);
      } else if (
        quantity >= bundleStore.getBundleProductsQuantitySum &&
        quantity <= bundleStore.numberOfSets
      ) {
        bundleStore.addBundleProduct(item, quantity);
      } else {
        return;
      }
      if (bundleStore.getBundleProductsQuantitySum > bundleStore.numberOfSets) {
        addProduct(
          item,
          quantity -
            (bundleStore.getBundleProductsQuantitySum -
              bundleStore.numberOfSets)
        );
      }
    };

    return {
      customProductGetters,
      getProductName,
      getProductHoverImage,
      bundleStore,
      getMaxNumberOfSets,
      getQuantity,
      isMaxItemsAdded,
      addProduct,
      getProductDescription
    };
  }
});
