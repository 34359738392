import findDeep from 'deepdash/findDeep';
import { CategoryTree } from '~/modules/GraphQL/types';

/**
 * Traverse Category Tree to find and return active category by "findBy" param
 *
 * @param categoryTree CategoryTree
 * @param toFind string
 * @param findBy string (default = url_path)
 *
 * @return CategoryTree
 */
export function findActiveCategory(categoryTree: CategoryTree, toFind: string, findBy: keyof CategoryTree = 'url_path'): CategoryTree | null {
  const categories = categoryTree?.children;

  let lookUp = categories ? findDeep(categories, (value: string, key: string) => key === findBy && value === toFind)?.parent ?? null : null;

  if (!lookUp) {
    const toFindSplited: any = toFind.split('/');

    let finderFinal = findDeep(categories, (value: string, key: string) => key === 'url_key' && value === toFindSplited[toFindSplited.length - 1]) ? findDeep(categories, (value: string, key: string) => key === 'url_key' && value === toFindSplited[toFindSplited.length - 1]) : null;
    let finderParent = finderFinal;

    if (toFindSplited.length > 1) {
      finderParent = findDeep(categories, (value: string, key: string) => key === 'url_key' && value === toFindSplited[toFindSplited.length - 2]), {
        childrenPath: toFindSplited[toFindSplited.length - 1],
      };

      if (finderParent) {
        if (finderParent.parent) {
          if (finderParent.parent.children) {
            const parentAvailable = findDeep(finderParent.parent.children, (value: string, key: string) => key === 'url_key' && value === toFindSplited[toFindSplited.length - 1]);
            if (parentAvailable) {
              finderFinal = findDeep(finderParent.parent.children, (value: string, key: string) => key === 'url_key' && value === toFindSplited[toFindSplited.length - 1]);
            }
          }
        }
      }
    }

    if (!finderFinal) {
      return null;
    }

    lookUp = finderFinal.parent ? finderFinal.parent : null;
  }

  return lookUp;
}
