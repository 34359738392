































import {
  computed,
  nextTick,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';

import { useUiState } from '~/composables';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { useBundleStore } from '~/diptyqueTheme/stores/bundleProduct';
import BundleProductsLayer from '~/modules/catalog/product/components/product-types/bundle/parts/BundleProductsLayer.vue';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
export default defineComponent({
  name: 'BundleProductStickyBar',
  components: { BundleProductsLayer },
  props: {
    product: {
      type: [Object, null],
      default: null
    }
  },
  setup(props) {
    const bundleStore = useBundleStore();
    const { toggleBundleLayer, isBundleLayerVisible } = useUiState();
    const getProductPrice = computed(() =>
      validateFraction(getPrice(props?.product).regular)
    );
    const isRecentlyOpened = ref(false);
    const {
      app: { i18n }
    } = useContext();
    watch(
      () => bundleStore.getBundleProductsQuantitySum,
      (sum) => {
        if (sum === +bundleStore.numberOfSets) {
          const delayToGiveClickOutsideBeProcessed = 100;
          isRecentlyOpened.value = true;
          setTimeout(() => {
            isRecentlyOpened.value = false;
          }, delayToGiveClickOutsideBeProcessed);

          if (!isBundleLayerVisible.value) {
            toggleBundleLayer();
          }
        }
      }
    );
    const getBarTitle = computed(() => {
      const counterText = `${bundleStore.getBundleProductsQuantitySum}/${bundleStore.numberOfSets}`;
      return `${i18n.t('Select {quantity} {name}', {
        quantity: bundleStore.numberOfSets,
        name: props.product?.choose_title
      })} (${counterText})`;
    });

    const getDynamicPrice = computed(() => bundleStore.getDynamicPrice);
    const isDynamicPrice = computed(
      () => props?.product?.price_type === 'DYNAMIC'
    );

    const handleClickOutside = ($event) => {
      const hasIgnoreClass = !!$event.target.closest('.ignore-click-outside');
      const isMaxProductsAdded = bundleStore.isMaxProductsAdded;
      const isOpen = isBundleLayerVisible.value;
      const closeIfClickedOutside =
        (isMaxProductsAdded && !isRecentlyOpened.value) || !hasIgnoreClass;

      if (isOpen && closeIfClickedOutside) {
        toggleBundleLayer();
      }
    };

    return {
      getProductPrice,
      isDynamicPrice,
      handleClickOutside,
      getDynamicPrice,
      validateFraction,
      getBarTitle,
      toggleBundleLayer,
      isBundleLayerVisible,
      bundleStore
    };
  }
});
