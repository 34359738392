import { createCustomFiltersFilterInput } from '~/diptyqueTheme/modules/catalog/category/customFilters/input/createOptionsInput';

export const getCustomFiltersByCategoryIdCommand = {
  execute: async (categoryId: number, options, query): Promise<[]> => {
    const filter = { filter: createCustomFiltersFilterInput(options, categoryId) };
    const { data } = await query(filter);
    return data?.getCustomFilters?.items ?? [];
  }
};

export default getCustomFiltersByCategoryIdCommand;
