












import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';
import type { RemovableFilterInterface } from '~/modules/catalog/category/components/filters/useFilters';
import { FilterTypeEnum } from '~/modules/catalog/category/config/config';
import VaimoQuickFilterBadge from '~/diptyqueTheme/components/organisms/category/VaimoQuickFilterBadge.vue';

export default defineComponent({
  components: {
    VaimoQuickFilterBadge
  },
  props: {
    removableFilters: {
      type: Array as PropType<RemovableFilterInterface[]>,
      default: () => []
    },
    showMore: Boolean as PropType<boolean>
  },
  emits: ['removeFilter'],
  setup(props, { emit }) {
    const {
      app: { i18n }
    } = useContext();

    const getLabel = (filter: RemovableFilterInterface) => {
      if (filter.type === FilterTypeEnum.YES_NO) {
        const yesNo = filter.label === '1' ? i18n.t('Yes') : i18n.t('No');
        return `${filter.name}: ${yesNo}`;
      }

      return filter.label;
    };

    const rmFilter = (filter) => {
      emit('removeFilter', { id: filter.id, value: filter.value });
    };

    return {
      getLabel,
      rmFilter
    };
  }
});
