import { computed, Ref, ref, set } from '@nuxtjs/composition-api';

import { useUiHelpers } from '~/composables';
import { useFiltersStore } from '~/diptyqueTheme/stores/filters';
import { FilterTypeEnum } from '~/modules/catalog/category/config/config';
import { getFilterConfig } from '~/modules/catalog/category/config/FiltersConfig';
import type { Aggregation, AggregationOption } from '~/modules/GraphQL/types';

export interface SelectedFiltersInterface {
  [p: string]: string[];
}

export interface RemovableFilterInterface {
  id: string;
  name: string;
  label: string;
  value: string;
  type: string;
}

export function useFilters(categoryId, aggregations: Ref) {
  const { getFacetsFromURL } = useUiHelpers();
  const filtersStore = useFiltersStore();
  const lastSelectedFilter = ref<{ attribute_code: string; options: string[] }>({ attribute_code: '', options: [] });
  const lastSelectedFilterCache = ref({});
  const appliedFilterCodes = computed(() => aggregations.value?.map((filter) => filter.attribute_code) || []);

  const lastSelectedFilterIfOnlyOne = () => {
    const categorySelectedFiltersKeys = Object.keys(filtersStore.selectedFilters[categoryId]);
    const filtered = categorySelectedFiltersKeys.reduce((memo, cur) => {
      if (filtersStore.selectedFilters[categoryId][cur].length) {
        memo.push({
          attribute_code: cur,
          options: filtersStore.selectedFilters[categoryId][cur]
        });
      }
      return memo;
    }, []);

    return filtered?.length === 1 ? filtered : null;
  };
  const countLastSelectedFilter = (filter, option) => {
    filtersStore.currentFilterCache = JSON.parse(JSON.stringify(filter));

    const isUnselectingAction = (code, value) => {
      return !(filtersStore.selectedFilters[categoryId][code] ?? []).includes(value);
    };

    if (lastSelectedFilter.value.attribute_code === filter.attribute_code) {
      if (isUnselectingAction) {
        // if unselecting
        lastSelectedFilter.value.options = lastSelectedFilter.value.options.filter((filter) => filter !== option.value);
        if (!filtersStore.selectedFilters[categoryId][filter.attribute_code]?.length) {
          lastSelectedFilter.value = lastSelectedFilterCache.value as { attribute_code: string; options: string[] };
        } else {
          // We do nothing here. It is required to keep lastSelectedFilter with no changes in this case
        }
      } else {
        // is selecting
        lastSelectedFilter.value.options.push(option.value);
      }
    } else {
      if (isUnselectingAction) {
        if (filtersStore.selectedFilters[categoryId][filter.attribute_code]?.length) {
          lastSelectedFilter.value = {
            attribute_code: filter.attribute_code,
            options: [option.value]
          };
        } else {
          // lastSelectedFilter.value = JSON.parse(JSON.stringify(lastSelectedFilterCache.value));
        }
      } else {
        lastSelectedFilterCache.value = JSON.parse(JSON.stringify(lastSelectedFilter.value));
        lastSelectedFilter.value = {
          attribute_code: filter.attribute_code,
          options: [option.value]
        };
      }
    }
    const _lastSelectedFilterIfOnlyOne = lastSelectedFilterIfOnlyOne();
    if (_lastSelectedFilterIfOnlyOne) {
      lastSelectedFilter.value = _lastSelectedFilterIfOnlyOne[0];
    }
    filtersStore.lastSelectedFilter = lastSelectedFilter.value;
  };

  const getSelectedFiltersFromUrl = (): object => {
    const { filters } = getFacetsFromURL();
    // remove url parameters which is not a filter
    Object.keys(filters).forEach((filter) => {
      if (!appliedFilterCodes.value.includes(filter)) {
        delete filters[filter];
      }
    });

    return filters;
  };

  if (typeof filtersStore.selectedFilters[categoryId] === 'undefined') {
    filtersStore.setSelectedFilters(getSelectedFiltersFromUrl(), categoryId);
  }
  const isFilterSelected = (name: string, value: string) => {
    const selected = (filtersStore.selectedFilters[categoryId][name] ?? []).find((selectedValue) => selectedValue === value);

    return selected ?? '';
  };

  const generateObjectForDelete = (code, value) => {
    const filter = {
      attribute_code: code,
      options: [{ label: value, value: value }],
      __typename: 'Aggregation'
    };
    const option = {
      label: value,
      value: value
    };
    countLastSelectedFilter(filter, option);
  };

  const removeFilter = (attrCode: string, valToRemove: string) => {
    generateObjectForDelete(attrCode, valToRemove);
    if (!filtersStore.selectedFilters[categoryId][attrCode]) return;
    filtersStore.selectedFilters[categoryId][attrCode] = filtersStore.selectedFilters[categoryId][attrCode].filter((value) => value !== valToRemove);
  };

  const selectFilter = (filter: Aggregation, option: AggregationOption) => {
    const config = getFilterConfig(filter.attribute_code);
    if (!filtersStore.selectedFilters[categoryId][filter.attribute_code]) {
      set(filtersStore.selectedFilters[categoryId], filter.attribute_code, []);
    }

    if (config.type === FilterTypeEnum.RADIO || config.type === FilterTypeEnum.YES_NO) {
      filtersStore.selectedFilters[categoryId][filter.attribute_code] = [option.value];
      return;
    }

    if (filtersStore.selectedFilters[categoryId][filter.attribute_code].find((f) => f === option.value)) {
      filtersStore.selectedFilters[categoryId][filter.attribute_code] = filtersStore.selectedFilters[categoryId][filter.attribute_code]?.filter(
        (f) => f !== option.value
      );
      countLastSelectedFilter(filter, option);
      return;
    }
    filtersStore.selectedFilters[categoryId][filter.attribute_code].push(String(option.value));
    countLastSelectedFilter(filter, option);
  };

  const getRemovableFilters = (filters: Aggregation[], selected: SelectedFiltersInterface): RemovableFilterInterface[] => {
    const result = [];

    filters.forEach((filter) => {
      filter.options.forEach((option) => {
        if ((selected[filter.attribute_code] ?? []).includes(option.value)) {
          const filterConfig = getFilterConfig(filter.attribute_code);

          result.push({
            id: filter.attribute_code,
            name: filter.label,
            label: option.label,
            value: option.value,
            type: filterConfig.type
          });
        }
      });
    });

    return result;
  };

  const clearFilters = () => {
    const selectedFiltersKeys = filtersStore.selectedFilters && Object.keys(filtersStore.selectedFilters[categoryId]);
    selectedFiltersKeys.forEach((key) => {
      filtersStore.selectedFilters[categoryId][key] = [];
    });
  };

  const result = {
    getSelectedFiltersFromUrl,
    lastSelectedFilter,
    clearFilters,
    lastSelectedFilterIfOnlyOne,
    isFilterSelected,
    removeFilter,
    selectFilter,
    selectedFilters: computed(() => filtersStore.selectedFilters[categoryId]),
    getRemovableFilters
  };
  return result;
}

export default useFilters;
