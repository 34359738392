import type { ProductList } from '~/modules/catalog/product/composables/useProduct';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { UseContextReturn } from '~/types/core';

export const getSliderProductsListCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    const { data } = await context.app.$vsf.$magento.api.sliderProducts(searchParams);

    return data?.products ?? null;
  }
};
