import type { ProductList } from '~/modules/catalog/product/composables/useProduct';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { UseContextReturn } from '~/types/core';

export const getProductListCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams, customQuery = 'products'): Promise<ProductList | null> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore "customGetFacetData" dynamically added by custom-middleware
    const { data } = await context.app.$vsf.$magento.api.productsWithPreview(searchParams, { products: customQuery });

    return data?.products ?? null;
  }
};
