

























































































































































































import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  ref,
  toRef,
  useContext,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api';
import {
  SfAddToCart,
  SfAlert,
  SfButton,
  SfColor,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
  SfRating,
  SfSelect
} from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';

import AddToWishlist from '~/components/AddToWishlist.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import { usePreviewMode } from '~/diptyqueTheme/composable';
import { getConfigurableProductPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductPriceCommand';
import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import {
  TabsConfig,
  useProductTabs
} from '~/modules/catalog/product/composables/useProductTabs';
import {
  getName as getProductName,
  getSwatchData as getProductSwatchData
} from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import { useCart } from '~/modules/core/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  getAverageRating,
  getTotalReviews
} from '~/modules/review/getters/reviewGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'ConfigurableProduct',
  components: {
    HTMLContent,
    LazyHydrate,
    SfAddToCart,
    SfButton,
    SfColor,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    SfSelect,
    SfAlert,
    AddToWishlist,
    SvgImage,
    ProductTabs
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null
    },
    isFetching: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const qty = ref(1);
    const product = toRef(props, 'product');
    const route = useRoute();
    const router = useRouter();
    const {
      addItem,
      error: cartError,
      loading: isCartLoading,
      canAddToCart
    } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();

    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const { app } = useContext();
    const { isPreviewMode } = usePreviewMode();

    const productShortDescription = computed(
      () => props.product?.short_description?.html || ''
    );

    const configurableOptions = computed(
      () => props.product?.configurable_options ?? []
    );

    const productConfiguration = ref(route.value.query);
    const productPrice = computed(() =>
      getConfigurableProductPriceCommand(props.product)
    );
    const productSpecialPrice = computed(() =>
      getConfigurableProductSpecialPriceCommand(props.product)
    );

    const {
      params: { id }
    } = route.value;
    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: id
        }
      },
      configurations: Object.entries(productConfiguration.value).map(
        (config) => config[1]
      ) as string[],
      is_preview: isPreviewMode.value
    });

    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const updateProductConfiguration = (label: string, value: string) => {
      if (productConfiguration.value[label] === value) return;

      productConfiguration.value[label] = value;
      const routeData = router.resolve({
        path: `${app.localePath(window.location.pathname)}`,
        query: {
          ...productConfiguration.value
        }
      });

      window.history.pushState({}, null, routeData.href);

      emit('fetchProduct', { query: getBaseSearchQuery() });
    };

    return {
      addItem,
      addItemToWishlist: addOrRemoveItem,
      canAddToCart,
      configurableOptions,
      updateProductConfiguration,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      isCartLoading,
      productConfiguration,
      productGallery,
      getProductName,
      getProductSwatchData,
      productPrice,
      productShortDescription,
      productSpecialPrice,
      qty,
      totalReviews,
      averageRating,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      addToCartError
    };
  }
});
