



















































import {
  computed,
  defineComponent,
  inject,
  PropType
} from '@nuxtjs/composition-api';
import { SfRadio, SfScrollable } from '@storefront-ui/vue';
import type { Aggregation } from '~/modules/GraphQL/types';
import { useFilterHelper } from '~/modules/catalog/category/composables/useFilterHelper';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  components: {
    SfRadio,
    SfScrollable
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true
    }
  },
  emits: ['selectFilter'],
  setup(_, context) {
    const { changeFilters } = useUiHelpers();
    const { isFilterSelected, selectedFilters, doApplyFilters } =
      inject('UseFiltersProvider');
    const { getLabel, resetCategory, isResetButtonVisible } =
      useFilterHelper(context);

    const selected = computed(
      () => (id: string, optVal: string) => isFilterSelected(id, optVal)
    );

    const reset = (code: string) => {
      resetCategory(code, selectedFilters.value);
    };

    const selectFilterValue = (option: Aggregation) => {
      context.emit('selectFilter', option);
      changeFilters(selectedFilters.value, false);
      doApplyFilters();
    };

    return {
      selected,
      getLabel,
      reset,
      isResetButtonVisible,
      selectedFilters,
      selectFilterValue
    };
  }
});
