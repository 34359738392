import { useContext } from '@nuxtjs/composition-api';
import { PriceRange } from '~/modules/GraphQL/types';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export interface PriceItem {
  price_range: PriceRange;
  sku: String;
}

export interface PriceItems {
  items: PriceItem[]
}

export const usePrice = () => {
  const context = useContext();

  const getPrices = async (variables: GetProductSearchParams): Promise<PriceItems> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore "customGetFacetData" dynamically added by custom-middleware
    const { data } = await await context.app.$vsf.$magento.api.customGetPrices<{ products: PriceItems }>(variables);

    return data?.products ?? { items: [] };
  };

  const getPricesBySku = async (skus: string[], pageSize = 20, currentPage = 1): Promise<PriceItems> => getPrices(
    { filter: { sku: { in: skus } }, pageSize, currentPage },
  );

  return { getPricesBySku, getPrices };
};
