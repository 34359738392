








































































import { computed, defineComponent } from '@vue/composition-api';

import { useBundleStore } from '~/diptyqueTheme/stores/bundleProduct';
export default defineComponent({
  name: 'BundleProductsLayer',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    product: {
      type: [Object, null],
      default: null
    }
  },
  setup(props) {
    const bundleStore = useBundleStore();
    const getSelectedProducts = computed(
      () => bundleStore.separateBundleProducts
    );
    const getBundleNumberOfSets = computed(() => bundleStore.numberOfSets || 0);

    const removeProduct = (index, id) => {
      bundleStore.removeProductFromLayer(index, id);
    };

    const isLoading = computed(() => bundleStore.loading);

    const addProductsToCart = async () => {
      if (
        getSelectedProducts.value.length !== getBundleNumberOfSets.value ||
        isLoading.value
      ) {
        return;
      }
      await bundleStore.addBundleProductsToCart(props.product);
    };

    return {
      removeProduct,
      getSelectedProducts,
      isLoading,
      addProductsToCart,
      getBundleNumberOfSets
    };
  }
});
