





















import { defineComponent, useContext, ref, useFetch } from '@nuxtjs/composition-api';
import { useContentful} from "~/integrations/contentful/src/composables/useContentful";
import type { PropType } from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'ProductReinsurance',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue')
  },
  props: {
    product: [Object, null] as PropType<Product>
  },
  setup( props ) {
    const reinsuranceItems = ref([]);
    const { app } = useContext();

    const getReinsurance = async () => {
      if (props.product?.reinsurance?.length) {
        const idsList = props.product?.reinsurance.reduce((memo, curr, i) => memo + curr.value + (i === props.product?.reinsurance.length - 1 ? '' : ','), '');
        const { getEntries, entries } = useContentful('getReinsurance');
        await getEntries({'sys.id[in]': idsList, limit: 10, include: 2, locale: app.i18n.localeProperties.contentfulLocale});
        entries.value?.items?.forEach(item => {
          // TODO: refactor when contentful API is stabilized (covers fields property removal in between)

          const itemFields = item?.fields || item;
          const visualFields = itemFields.visual?.fields || itemFields.visual;
          const visualSrc = visualFields?.vis?.fields?.file?.url || visualFields?.vis?.file?.url;
          const visualTitle = visualFields?.fields?.etitle || visualFields?.fields?.vis?.fields?.file?.title || visualFields?.vis?.file?.title || itemFields.eTitle;

          reinsuranceItems.value.push({
            description: itemFields.descr,
            image: {
              src: visualSrc ? 'https:' + visualSrc : '',
              title: visualTitle
            }
          })
        });
      }
    }

    useFetch(async () => {
      await getReinsurance();
    })

    return {
      reinsuranceItems
    }
  }
})
